<template>
  <v-app>
    <v-main class="bg-color">
      <v-container fluid>
        <v-row justify="center" class="mt-16">
          <v-col cols="12" sm="10" md="10" lg="4">
            <v-card class="pa-2 rounded-lg" outlined tile>
              <v-card-title class="flex w-full justify-center">
                <v-avatar class="bg-color ma-0 pa-2" size="130">
                  <v-img
                    lazy-src="/static/images/logo.png"
                    height="150"
                    width="250"
                    contain
                    src="/static/images/logo.png"
                  ></v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text>
                <form v-on:submit.prevent="onSubmit">
                  <v-card-text>
                    <v-text-field
                      label="Username"
                      prepend-icon="mdi-account-circle"
                      rounded
                      dense
                      outlined
                      v-model="username"
                      :error-messages="usernameErrors"
                      @input="$v.username.$touch()"
                      @blur="$v.username.$touch()"
                    />
                    <v-text-field
                      label="Password"
                      :type="isShowPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowPassword = !isShowPassword"
                      rounded
                      dense
                      outlined
                      v-model="password"
                      :error-messages="passwordErrors"
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn block class="bg-color" dark type="submit">
                      <v-icon left> mdi-login</v-icon>
                      Login
                    </v-btn>
                  </v-card-actions>
                </form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required }
  },
  data() {
    return {
      username: "",
      password: "",
      isShowPassword: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.isLoggedIn;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    }
  },
  created() {
    if (this.isLoggedIn) this.$router.push("/dashboard");
  },
  methods: {
    onSubmit() {
      // this.$router.push("/");
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { username, password } = this;
        this.$store
          .dispatch("auth/login", {
            username,
            password
          })
          .then(
            () => {
              this.$router.push("/");
            },
            error => {
              this.loading = false;
              this.errorMessege =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-color {
  background: linear-gradient(to right, #5a056f, #6a00c3);
}
</style>
