import Vue from "vue";
export const roleMenu = {
  dashboard: "dashboard",
  admin: "admin",
  member: "member",
  deposit: "deposit",
  withdraw: "withdraw",
  transfer: "transfer"
};
export const roleOptions = {
  create: "create",
  update: "update",
  delete: "delete",
  deposit: "deposit",
  withdraw: "withdraw",
  transaction: "transaction",
  filter: "filter",
  today: "today"
};

export const roles = {
  dashboard: {
    available: false,
    menu: [roleOptions.filter, roleOptions.today]
  },
  admin: {
    available: false
  },
  member: {
    available: false,
    credit: false,
    menu: [
      roleOptions.create,
      roleOptions.update,
      roleOptions.delete,
      roleOptions.deposit,
      roleOptions.withdraw,
      roleOptions.transaction
    ]
  },
  deposit: {
    available: false,
    menu: [roleOptions.create, roleOptions.update, roleOptions.delete]
  },
  withdraw: {
    available: false,
    menu: [roleOptions.create, roleOptions.update, roleOptions.delete]
  },
  transfer: {
    available: false,
    menu: [roleOptions.create, roleOptions.update, roleOptions.delete]
  }
};

export const menuPermission = (roleOption, role) => {
  return roleOption[role] ? roleOption[role].available : false;
};

export const actionPermisstion = (role, menu) => {
  let roleOption = JSON.parse(localStorage.getItem("user"));

  roleOption = JSON.parse(roleOption.role);

  const roleData = roleOption[role].menu;
  if (!roleData) return false;

  return !!roleData.find(item => item === menu);
};

Vue.prototype.$roleMenu = roleMenu;
Vue.prototype.$roleOptions = roleOptions;

Vue.prototype.$menuPermission = menuPermission;
Vue.prototype.$actionPermisstion = actionPermisstion;
