<template>
  <div>
    <!-- set progressbar -->
    <vue-progress-bar />

    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
        color="#A300EF"
      ></v-progress-circular>
    </v-overlay>

    <!-- Error dialog -->
    <v-snackbar v-model="error" :vertical="true" top>
      <h1 class="text-lg font-bold py-2">Error</h1>
      <p>{{ errorMessage }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="errorHide">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Alert -->
    <v-snackbar
      v-model="alert"
      :timeout="2000"
      :color="statusMessage.type !== 'success' ? '#e74c3c' : '#27ae60'"
      dark
      top
      right
    >
      <div v-if="alert">
        <span v-if="statusMessage.type === 'success'" class="text-white "
          ><v-icon>mdi-check-circle</v-icon> {{ statusMessage.text }}</span
        >
        <span v-else class="text-white"
          ><v-icon>mdi-alert-octagon</v-icon> {{ statusMessage.text }}</span
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations({
      errorHide: "loader/errorHide",
      alertHide: "notification/hide"
    })
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters({
      loading: "loader/loading",
      error: "loader/error",
      errorMessage: "loader/errorMessage",
      statusAlert: "notification/status",
      statusMessage: "notification/message"
    }),
    alert: {
      get() {
        return this.statusAlert;
      },
      set() {
        this.alertHide();
      }
    }
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  }
};
</script>
