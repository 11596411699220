import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        purple: "#6101CF",
        primary: "#FF5C00"
      },
      light: {
        purple: "#6101CF",
        primary: "#FF5C00"
      }
    }
  },
  icons: {
    iconfont: "fa" || "mdi"
  }
});
