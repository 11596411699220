import rewardService from "./../services/reward.service";
import FileService from "./../services/file.service";
const state = {
  editID: null,
  rewardForm: {
    title: "",
    sub_title: "",
    description: "",
    point: 0,
    took_reward: 0,
    balance: 0,
    image: [],
    type_of_reward: "",
    is_active: true
  },
  imagePreview: null,
  rewardItems: [],
  rewardMeta: {}
};
const getters = {
  rewardForm: ({ rewardForm }) => rewardForm,
  rewardItems: ({ rewardItems }) => rewardItems,
  rewardMeta: ({ rewardMeta }) => rewardMeta,
  editID: ({ editID }) => editID,
  imagePreview: ({ imagePreview }) => imagePreview
};
const actions = {
  async findAll({ commit }, params) {
    try {
      const response = await rewardService.findAll(params);

      commit("findAll", response.data.items);
      commit("setMeta", response.data.meta);
    } catch (error) {
      new Promise.reject(error);
    }
  },
  resetForm({ commit }) {
    commit("resetForm");
  },
  async createReward({ commit }, data) {
    try {
      const formData = new FormData();
      formData.append("file", data.image);

      const image = await FileService.upload(formData);
      if (image.status !== 201) return false;

      data.image = image.data.url;
      const response = await rewardService.create(data);

      if (response.status === 201) {
        commit("createReward", response.data);
        commit("clearFormReward");
      }
    } catch (error) {
      new Promise.reject(error);
    }
  },
  setFormReward({ commit }, data) {
    commit("setFormReward", data);
  },
  async updateReward({ commit }, data) {
    try {
      const formData = new FormData(); 
      const image = await FileService.upload(formData);
       
      if (image.status !== 201) return false;

      data.image = image.data.url;

      const response = await rewardService.update(data.id, data);

      commit("updateReward", response.data);
    } catch (error) {
      new Promise.reject(error);
    }
  },
  async deleteReward({ commit }, data) {
    try {
      const response = await rewardService.delete(data.id);
      // state.rewardItems = state.rewardItems.filter(({ id }) => id != data.id);
      commit("deleteReward", response.data);
      // commit("findAll", state.rewardItems);
    } catch (error) {
      new Promise.reject(error);
    }
  },
  clearFormReward({ commit }) {
    commit("clearFormReward");
  }
};
const mutations = {
  findAll(state, data) {
    if (data.length > 0) {
      data.map(item => {
        const result = {
          id: item.id,
          title: item.title,
          description: item.description,
          point: item.point,
          took_reward: item.took_reward,
          balance: item.balance,
          image: []
        };
        state.rewardItems = [...state.rewardItems, result];
      });
    }
    state.rewardItems = data;
  },
  resetForm(state) {
    state.editID = null;
    state.rewardForm = {
      title: "",
      sub_title: "",
      description: "",
      point: 0,
      took_reward: 0,
      balance: 0,
      image: [],
      type_of_reward: "",
      is_active: true
    };
  },
  setMeta(state, data) {
    state.rewardMeta = data;
  },
  deleteReward(state, value) {
    const removeItem = state.rewardItems.filter(({ id }) => id !== value.id);
    state.rewardItems = removeItem;
  },
  setFormReward(state, values) {
    state.editID = values.id;
    const data = {
      id: values.id,
      title: values.title,
      description: values.description,
      point: values.point,
      took_reward: values.took_reward,
      balance: values.balance,
      sub_title: values.sub_title,
      type_of_reward: values.type_of_reward,
      image: []
    };
    state.imagePreview = values.image;
    state.rewardForm = data;
  },
  updateReward(state, values) {
    const results = [];

    state.rewardItems.map((item, index) => {
      if (state.editID === item.id) {
        results[index] = values;
      } else {
        results[index] = item;
      }
    });
    state.rewardItems = results;
    state.editID = null;
  },
  createReward(state, data) {
    if (state.rewardItems.length !== 10) {
      state.rewardItems.push(data);
    }
    state.rewardMeta.totalItems++;
    state.rewardMeta.totalPages = Math.ceil(
      state.rewardMeta.totalItems / state.rewardMeta.itemsPerPage
    );
  },
  clearFormReward(state) {
    state.rewardForm = {
      title: null,
      description: null,
      point: 0,
      took_reward: 0,
      balance: 0
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
