import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import { setupProgressbar } from "./utils/progressbar";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import VueSocketIO from "vue-socket.io";
import filter from "./utils/filter";
import SocketIO from "socket.io-client";
// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });

import "./assets/styles/custom.css";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

const socketConnection = SocketIO(process.env.VUE_APP_API_URL, {
  path: "/websocket/",
  transports: ["websocket"]
});

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socketConnection
  })
);

setupProgressbar(Vue);

export default new Vue({
  router,
  store,
  vuetify,
  filter,
  render: h => h(App)
}).$mount("#app");
