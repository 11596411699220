export default function authHeader() {
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` }; // for Spring Boot back-end
  } else {
    return {};
  }
}
