import { backendAPI } from "../utils/axios";

class AuthService {
  async login({ username, password }) {
    return backendAPI
      .post("/auth/login", {
        username,
        password
      })
      .then(({ data: user }) => {
        if (user.token) {
          localStorage.setItem("user", JSON.stringify(user));
        }

        return user;
      });
  }

  logout() {
    localStorage.removeItem("user");

    location.href = "/login";
  }
}

export default new AuthService();
