<template>
  <v-app>
    <v-app-bar app color="#6101CF" dense dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-bind="attrs" class="focus:outline-none" v-on="on">
            <v-icon>fas fa-bell</v-icon>
            <v-badge v-if="count !== 0" color="red" :content="count"> </v-badge>
          </v-btn>
        </template>

        <v-list max-height="500" color="#fff" class="overflow-y-scroll pa-0">
          <v-list-item
            v-for="(item, index) in notificationItems"
            :key="index"
            link
            @click="onDeleteNotification(item)"
          >
            <v-list-item-title>{{ item.data.message }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >User: {{ userData.username }}</v-list-item-title
                >
                <!-- <v-list-item-subtitle
                  >ตำแหน่ง: {{ userData.role }}</v-list-item-subtitle
                > -->
                <v-list-item-subtitle
                  >เงินเดือนคงเหลือ: {{ userData.base_salary }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onLogout">
              <v-icon>mdi-logout</v-icon> Loguout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app dark color="#1B1E37">
      <template v-slot:prepend>
        <div class="py-6 justify-center d-flex">
          <v-img
            lazy-src="/static/images/logo.png"
            max-height="150"
            max-width="120"
            src="/static/images/logo.png"
          ></v-img>
        </div>
      </template>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="menu in menus"
          :key="menu.link"
          link
          exact
          :href="menu.link"
          v-show="permisstion(menu.value) || menu.value === 'all'"
        >
          <v-list-item-content>
            <v-list-item-title>{{ menu.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group color="#ffff">
          <template v-slot:activator>
            <v-list-item-title>{{ horseMenu.text }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="menu in horseMenu.menus"
            :key="menu.link"
            link
            :href="menu.link"
          >
            <v-list-item-content class="px-4">
              <v-list-item-title>
                <v-icon> mdi-menu-right </v-icon>
                {{ menu.text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="grey lighten-3">
      <v-container fluid class="py-8 px-6">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationService from "./../../services/notification.service";
import { menuPermission } from "./../../utils/role";
export default {
  name: "MainLayout",
  data: () => ({
    drawer: null,
    userData: JSON.parse(localStorage.getItem("user")),
    menus: [
      { name: "Dashboard", link: "/", text: "Dashboard", value: "dashboard" },
      { name: "Admin", link: "/admin", text: "Admin", value: "admin" },
      { name: "Member", link: "/member", text: "สมาชิก", value: "member" },
      {
        name: "MemberProvider",
        link: "/member-provider",
        text: "สมาชิกของผู้ให้บริการ",
        value: "member"
      },
      {
        name: "Deposit",
        link: "/deposit",
        text: "เติมเครดิต",
        value: "deposit"
      },
      {
        name: "Withdraw",
        link: "/withdraw",
        text: "ถอนเครดิต",
        value: "withdraw"
      },
      {
        name: "TransferIn",
        link: "/transfer-in",
        text: "ทรานสเฟอร์อิน",
        value: "transfer"
      },
      {
        name: "TransferOut",
        link: "/transfer-out",
        text: "ทรานสเฟอร์เอาต์",
        value: "transfer"
      },
      {
        name: "Transaction",
        link: "/transaction",
        text: "ทรานแซคชั่น",
        value: "transfer"
      },
      { name: "Bonus", link: "/bonus", text: "โบนัส", value: "all" },
      { name: "Reward", link: "/reward", text: "รีวอร์ด", value: "all" },
      {
        name: "Take Reward",
        link: "/take-reward",
        text: "Take รีวอร์ด",
        value: "all"
      }
      // { name: "Provider", link: "/provider", text: "ผู้ให้บริการ" },
      // { name: "Bank", link: "/bank", text: "ธนาคาร" }
    ],
    horseMenu: {
      text: "แข่งม้า",
      menus: [
        { name: "Horse", link: "/horse", text: "ม้า" },
        { name: "Jockey", link: "/jockey", text: "คนขี่" },
        { name: "Match", link: "/match", text: "แมทซ์" },
        // { name: "Bill", link: "/bill", text: "บิล" },
        { name: "Bet", link: "/bet", text: "เบท" },
        { name: "Partner", link: "/partner", text: "คอมมิชชั่น" }
      ]
    }
  }),
  methods: {
    ...mapActions({
      getNotification: "notification/getNotification"
    }),
    onLogout() {
      this.$store.dispatch("auth/logout");
      // return this.$router.push("/login");
    },
    async onDeleteNotification(item) {
      const { action } = item.data.data;
      const { status } = await NotificationService.delete(item.id);

      if (status === 200) {
        this.$router.push(`/${action.toLowerCase()}`);
        this.getNotification();
      }
    },
    permisstion(role) {
      const userData = JSON.parse(localStorage.getItem("user"));

      return menuPermission(JSON.parse(userData.role), role);
    }
  },
  computed: {
    ...mapGetters({
      count: "notification/count",
      notificationItems: "notification/notificationItems"
    })
  },
  mounted() {
    this.getNotification();
    this.sockets.subscribe("getSocketNotificationAdminWithdraw", response => {
      if (response === "fetchWithdraw") {
        this.getNotification();
      }
    });

    this.sockets.subscribe(
      "getSocketNotificationAdminApproveDeposit",
      response => {
        if (response === "fetchApproveDeposit") {
          this.getNotification();
        }
      }
    );

    this.sockets.subscribe(
      "getSocketNotificationAdminApproveWithdraw",
      response => {
        if (response === "fetchApproveWithdraw") {
          this.getNotification();
        }
      }
    );
  }
};
</script>

<style lang="scss" scoped>
.bg-color {
  background: linear-gradient(to right, #5a056f, #6a00c3);
}
</style>
