import Vue from "vue";
import { bankData } from "./bankData";
import moment from "moment-timezone";

Vue.filter("dateTimeSMSLocal", value => {
  let dateLocal = "";

  const date = new Date(
    moment(value)
      .add(-7, "hours")
      .format()
  );


  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const setDate = new Date(year, month, day);
  dateLocal = setDate.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "2-digit",
    day: "numeric"
  });

  return `${dateLocal} ${date.toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit"
  })}`;
});

Vue.filter("dateTimeLocal", value => {
  let dateLocal = "";

  const date = new Date(moment(value).format());

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const setDate = new Date(year, month, day);
  dateLocal = setDate.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "2-digit",
    day: "numeric"
  });

  return `${dateLocal} ${date.toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit"
  })}`;
});

Vue.filter("dateTimeDB", value => {
  let dateLocal = "";

  const date = new Date(
    moment(value)
      .add(-7, "hour")
      .format()
  );

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const setDate = new Date(year, month, day);
  dateLocal = setDate.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "2-digit",
    day: "numeric"
  });

  return `${dateLocal} ${date.toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit"
  })}`;
});

// Bank
Vue.filter("bankImage", value => {
  const item = bankData.find(({ name }) => name === value);
  return item.image;
});
Vue.filter("bankColor", value => {
  const item = bankData.find(({ name }) => name === value);
  return item.color;
});

Vue.filter("bankNiceName", value => {
  const item = bankData.find(({ name }) => name === value);
  return item.nice_name;
});

Vue.filter("getFormatCurrency", values => {
  var formatter = new Intl.NumberFormat("th-TH");

  return formatter.format(values);
});
Vue.filter("formatThTransaction", value => {
  switch (value) {
    case "deposit":
      return "เติมเครดิต";
    case "withdraw":
      return "ถอนเครดิต";
    case "transferin":
      return "โยกเครดิตเข้า";
    case "transferout":
      return "โยกเครดิตออก";
    case "updatecredit":
      return "***อัพเดตเครดิต";
    default:
      return "";
  }
});

Vue.filter("statusFilter", value => {
  switch (value) {
    case "pending":
      return "รอทำรายการ";
    case "failure":
      return "ไม่สำเร็จ";
    case "done":
      return "สำเร็จ";
    default:
      return "";
  }
});

Vue.filter("transactionStatus", value => {
  switch (value) {
    case true:
      return "";
    case false:
      return "ลบ";
    default:
      return "";
  }
});

Vue.filter("statusMember", value => {
  switch (value) {
    case "done":
      return "สำเร็จ";
    case "pending":
      return "กำลังดำเนินการ";
    case "failure":
      return "ไม่สำเร็จ";
    default:
      return "";
  }
});
