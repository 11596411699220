import { backendAPI } from "../utils/axios";
import isEmpty from "lodash/isEmpty";
class FileService {
  async upload(file) {
    console.log(isEmpty(file));
    if (file) return await backendAPI.post(`files/upload`, file);
  }
}

export default new FileService();
