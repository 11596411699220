import { cloneDeep } from "lodash";
const state = {
  loading: false,
  error: false,
  errorMessage: {},
  errorCode: null,
  requestsPending: 0,
  isActiveLoader: true
};
const getters = {
  loading: ({ loading }) => cloneDeep(loading),
  error: ({ error }) => error,
  errorMessage: ({ errorMessage }) => errorMessage,
  errorCode: ({ errorCode }) => errorCode,
  isActiveLoader: ({ isActiveLoader }) => isActiveLoader
};
const actions = {
  setActiveLoader({ commit }, data) {
    commit("setActiveLoader", data);
  },
  setError({ commit }, data) {
    commit("setError", data);
  },
  errorShow({ commit }) {
    commit("errorShow");
  },
  errorHide({ commit }) {
    commit("errorHide");
  },
  show({ commit }) {
    commit("show");
  },
  hide({ commit }) {
    commit("hide");
  },
  pending({ commit }) {
    commit("pending");
  },
  done({ commit }) {
    commit("done");
  }
};
const mutations = {
  errorShow(state) {
    state.error = true;
    state.loading = false;
  },
  errorHide(state) {
    state.error = false;
    state.loading = false;
  },
  setError(state, data) {
    state.errorMessage = data;
  },
  setActiveLoader(state, data) {
    state.isActiveLoader = data;
  },
  show(state) {
    state.loading = true;
  },
  hide(state) {
    state.loading = false;
  },
  pending(state) {
    if (state.requestsPending === 0) {
      this.commit("loader/show");
    }

    state.requestsPending++;
  },
  done(state) {
    if (state.requestsPending >= 1) {
      state.requestsPending--;
    }

    if (state.requestsPending <= 0) {
      this.commit("loader/hide");
    }
  }
};
export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
