import { backendAPI } from "./../utils/axios";

const prefix = "/notification/";
class NotificationService {
  async findAllUser(id) { 
    const response = await backendAPI.get(`${prefix}user/${id}`, {
      showLoader: false
    });

    return response;
  }

  async delete(id) {
    const response = await backendAPI.delete(`${prefix}${id}`);

    return response;
  }
}

export default new NotificationService();
