export const bankData = [
  {
    id: "2",
    name: "KBANK",
    nice_name: "ธนาคารกสิกรไทย",
    official_name: "KASIKORNBANK PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/kbank.svg ",
    color: "#138F2DFF",
    is_active: "t",
    created_at: "17/3/2021 10:41:53.868734",
    updated_at: "17/3/2021 10:41:53.868734",
    deleted_at: ""
  },
  {
    id: "3",
    name: "KTB",
    nice_name: "ธนาคารกรุงไทย",
    official_name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/ktb.svg ",
    color: "#1BA5E1FF",
    is_active: "t",
    created_at: "17/3/2021 10:43:42.447736",
    updated_at: "17/3/2021 10:43:42.447736",
    deleted_at: ""
  },
  {
    id: "4",
    name: "TMB",
    nice_name: "ธนาคารทหารไทย",
    official_name: "TMB BANK PUBLIC COMPANY LIMITED.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/tmb.svg ",
    color: "#1279BEFF",
    is_active: "t",
    created_at: "17/3/2021 10:45:26.201172",
    updated_at: "17/3/2021 10:45:26.201172",
    deleted_at: ""
  },
  {
    id: "5",
    name: "SCB",
    nice_name: "ธนาคารไทยพาณิชย์",
    official_name: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/scb.svg ",
    color: "#4E2E7FFF",
    is_active: "t",
    created_at: "17/3/2021 10:46:11.532912",
    updated_at: "17/3/2021 10:46:11.532912",
    deleted_at: ""
  },
  {
    id: "6",
    name: "BAY",
    nice_name: "ธนาคารกรุงศรีอยุธยา",
    official_name: "BANK OF AYUDHYA PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/bay.svg ",
    color: "#FEC43BFF",
    is_active: "t",
    created_at: "17/3/2021 10:46:59.256435",
    updated_at: "17/3/2021 10:46:59.256435",
    deleted_at: ""
  },
  {
    id: "7",
    name: "TBANK",
    nice_name: "ธนาคารธนชาต",
    official_name: "THANACHART BANK PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/tbank.svg ",
    color: "#FC4F1FFF",
    is_active: "t",
    created_at: "17/3/2021 10:47:40.667808",
    updated_at: "17/3/2021 10:47:40.667808",
    deleted_at: ""
  },
  {
    id: "8",
    name: "UOB",
    nice_name: "ธนาคารยูโอบี",
    official_name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/uob.svg ",
    color: "#0B3979FF",
    is_active: "t",
    created_at: "17/3/2021 10:48:48.871546",
    updated_at: "17/3/2021 10:48:48.871546",
    deleted_at: ""
  },
  {
    id: "9",
    name: "GSB",
    nice_name: "ธนาคารออมสิน",
    official_name: "THE GOVERNMENT SAVINGS BANK",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/gsb.svg ",
    color: "#EB198DFF",
    is_active: "t",
    created_at: "17/3/2021 10:49:40.095219",
    updated_at: "17/3/2021 10:49:40.095219",
    deleted_at: ""
  },
  {
    id: "1",
    name: "BBL",
    nice_name: "ธนาคารกรุงเทพ",
    official_name: "BANGKOK BANK PUBLIC COMPANY LTD.",
    image: "https://sc99-dev.s3-ap-southeast-1.amazonaws.com/bank/bbl.svg",
    color: "#1e4598",
    is_active: "t",
    created_at: "17/3/2021 10:14:36.160572",
    updated_at: "17/3/2021 10:52:55.152151",
    deleted_at: ""
  }
];
