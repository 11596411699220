import axios from "axios";
import app from "@/main";

import authHeader from "./../services/auth-header";
require("dotenv").config();

axios.defaults.showLoader = true;
export const backendAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api` || "http://localhost:3000/api"
});

backendAPI.interceptors.request.use(
  config => {
    const path = app._router.history.current.path;

    if (config.showLoader && path !== "/match" && path !== "/bet") {
      app.$store.dispatch("loader/pending");
      app.$Progress.start();
    }

    return config;
  },
  error => {
    let response = error.response;

    app.$store.dispatch("loader/errorShow");
    app.$store.dispatch("loader/setError", response.data);

    if (error.config.showLoader) {
      app.$Progress.finish();
      app.$store.dispatch("loader/done");
    }

    return Promise.reject(error);
  }
);

backendAPI.interceptors.response.use(
  response => {
    app.$Progress.finish(); // finish when a response is received
    if (response.config.showLoader) {
      app.$store.dispatch("loader/done");
    }

    return response;
  },
  error => {
    let response = error.response;

    response.status === 401 && app.$store.dispatch("auth/logout");

    if (response.config.showLoader) {
      app.$store.dispatch("loader/done");
      app.$store.dispatch("loader/errorShow");
      app.$store.dispatch("loader/setError", response.data);
      app.$Progress.finish();
    }

    return Promise.reject(error);
  }
);

backendAPI.interceptors.request.use(
  function(config) {
    config.headers = authHeader();
    return config;
  },
  null,
  { synchronous: true }
);
