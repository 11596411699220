<template>
  <div id="app">
    <!-- for example router view -->
    <router-view />
    <Notification />
  </div>
</template>

<script>
import Notification from "@/components/Nottfication";
export default {
  components: { Notification },
  watch: {
    $route(value) {
      const { meta } = value;
      const userData = JSON.parse(localStorage.getItem("user"));
      const role = meta.requiresAuth;

      if (typeof role === "boolean") {
        if (!role) {
          this.$router.back();
        }
      } else {
        if (!this.$menuPermission(JSON.parse(userData.role), role)) {
          this.$router.back();
        }
      }
    }
  }
};
</script>
