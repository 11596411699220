import Vue from "vue";
import Vuex from "vuex";

import AuthModule from "./auth.module";
import BonusModule from "./bonus.module";
import RewardModule from "./reward.module";
import LoadingModule from "./loader.module";
import UserModule from "./user.module";
import NotificationModule from "./notification.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    bonus: BonusModule,
    reward: RewardModule,
    user: UserModule,
    loader: LoadingModule,
    notification: NotificationModule
  }
});
