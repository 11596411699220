import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import MainLayout from "../components/layouts/MainLayout.vue";

import { roleMenu } from "./../utils/role";
require("dotenv").config();

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
        meta: { requiresAuth: roleMenu.dashboard }
      },
      {
        path: "/admin",
        name: "Admin",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Admin/Main.vue"),
        meta: { requiresAuth: roleMenu.admin }
      },
      {
        path: "/member",
        name: "Member",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Member/Main.vue"),
        meta: { requiresAuth: roleMenu.member }
      },
      {
        path: "/deposit",
        name: "Deposit",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Deposit/Main.vue"),
        meta: { requiresAuth: roleMenu.deposit }
      },
      {
        path: "/withdraw",
        name: "Withdraw",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Withdraw/Main.vue"),
        meta: { requiresAuth: roleMenu.withdraw }
      },
      {
        path: "/transfer-in",
        name: "TransferIn",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/TransferIn/Main.vue"),
        meta: { requiresAuth: roleMenu.transfer }
      },
      {
        path: "/transfer-out",
        name: "TransferOut",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/TransferOut/Main.vue"
          ),
        meta: { requiresAuth: roleMenu.transfer }
      },
      {
        path: "/transaction",
        name: "Transaction",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/Transaction/Main.vue"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "/bonus",
        name: "Bonus",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Bonus/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/reward",
        name: "Reward",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Reward/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/take-reward",
        name: "TakeReward",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/TakeReward/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/provider",
        name: "Provider",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Provider/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/bank",
        name: "Bank",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Bank/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/member-provider",
        name: "MemberProvider",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/MemberProvider/Main.vue"
          ),
        meta: { requiresAuth: roleMenu.member }
      },
      {
        path: "/horse",
        name: "Horse",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Horse/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/jockey",
        name: "Jockey",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Jockey/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/bill",
        name: "Bill",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Bill/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/bet",
        name: "Bet",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Bet/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/bet/detail",
        name: "BetDetail",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Bet/Detail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/match",
        name: "Match",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Match/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/partner",
        name: "Partner",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/Partner/MainDate.vue"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "/partner/by-date",
        name: "PartnerDate",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Partner/Main.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/partner/detail",
        name: "PartnerDetail",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/Partner/Detail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/partner/commission",
        name: "PartnerDetail",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/Partner/Commission.vue"
          ),
        meta: { requiresAuth: true }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");



  // trying to access a restricted page + not logged in
  // redirect to login page

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
