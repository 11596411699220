import UserService from "../services/user.service";

const state = {
  userItems: [],
  userMeta: {},
  editID: null,
  userForm: {
    username: null,
    password: null,
    name: null,
    nickname: null,
    tel: null,
    birth_date: null,
    picture: null,
    base_salary: 0,
    balance_salary: 0
  }
};
const getters = {
  userItems: ({ userItems }) => userItems,
  userMeta: ({ userMeta }) => userMeta,
  userForm: ({ userForm }) => userForm,
  editID: ({ editID }) => editID
};
const actions = {
  async findAll({ commit }, params) {
    try {
      const response = await UserService.findAll(params);
      commit("setUserItems", response.data.items);
      commit("setUserMeta", response.data.meta);
    } catch (error) {
      new Promise.reject(error);
    }
  },
  async createAdmin({ commit }, values) {
    try {
      const response = await UserService.create(values);

      if (response.status === 201) {
        commit("setUserItem", response.data);
      }
    } catch (error) {
      new Promise.reject(error);
    }
  }
};
const mutations = {
  setUserItems(state, data) {
    state.userItems = data;
  },
  setUserMeta(state, data) {
    state.userMeta = data;
  },
  setUserItem(state, data) {
    state.userItems.push(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
