import NotificationService from "./../services/notification.service";
const state = {
  message: {
    type: "success",
    text: null
  },
  notificationItems: [],
  count: 0,
  status: false
};
const getters = {
  message: ({ message }) => message,
  status: ({ status }) => status,
  notificationItems: ({ notificationItems }) => notificationItems,
  count: ({ count }) => count
};
const actions = {
  show({ commit }, message) {
    commit("show", message);
  },
  hide({ commit }) {
    commit("hide");
  },
  async getNotification({ commit }) {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await NotificationService.findAllUser(user.id);
    commit("setNotification", response.data);
  }
};
const mutations = {
  show(state, message) {
    state.message = message;
    state.status = true;
  },
  hide(state) {
    state.status = false;
    state.message = false;
  },
  setNotification(state, data) {
    state.count = data.length;
    state.notificationItems = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
