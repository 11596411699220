import VueProgressBar from "vue-progressbar";

export const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

export function setupProgressbar(app) {
  app.use(VueProgressBar, options);
}
