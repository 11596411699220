import BonusService from "./../services/bonus.service";
import cloneDeep from "lodash/cloneDeep";
const state = {
  bonusItems: [],
  bonusMeta: {},
  createBonusDialog: false,
  pageTotal: 0,
  pageSize: 10,
  formBonus: {
    title: null,
    max: null,
    type_transaction_bonus: null,
    description: null,
    type_of_bonus: null,
    bonus: 0,
    effective_date: new Date().toISOString().substr(0, 10),
    expire_date: new Date().toISOString().substr(0, 10),
    is_active: true
  }
};

const getters = {
  bonusItems: ({ bonusItems }) => bonusItems,
  createBonusDialog: ({ createBonusDialog }) => createBonusDialog,
  formBonus: ({ formBonus }) => formBonus,
  pageSize: ({ pageSize }) => pageSize,
  pageTotal: ({ pageTotal }) => pageTotal,
  bonusMeta: ({ bonusMeta }) => bonusMeta
};

const actions = {
  async findAll({ commit }, data) {
    try {
      const response = await BonusService.findAll(data);
      commit("findAll", response.data.items);
      commit("setMeta", response.data.meta);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setBonusItem({ commit }, values) {
    commit("findAll", values);
  },
  setCreateBonusDialog({ commit }, value) {
    commit("setCreateBonusDialog", value);

    if (!state.createBonusDialog) {
      commit("clearState");
    }
  },
  clearState({ commit }) {
    commit("clearState");
  },
  setFormBonus({ commit }, data) {
    commit("setFormBonus", data);
  }
};

const mutations = {
  findAll(state, data) {
    if (data.length > 0) {
      data.map(item => {
        const result = {
          id: item.id,
          title: item.title,
          description: item.description,
          type_of_bonus: item.type_of_bonus,
          bonus: item.bonus,
          effective_date: new Date(item.effective_date)
            .toISOString()
            .substr(0, 10),
          expire_date: new Date(item.expire_date).toISOString().substr(0, 10),
          is_active: item.is_active
        };
        state.bonusItems = [...state.bonusItems, result];
      });
    }
    state.bonusItems = data;
  },
  setMeta(state, meta) {
    state.bonusMeta = meta;
    state.pageSize = meta.itemCount;
    state.pageTotal = meta.totalPages;
  },
  setCreateBonusDialog(state, value) {
    state.createBonusDialog = cloneDeep(value);
  },
  setFormBonus(state, data) {
    state.formBonus = data;
  },
  clearState(state) {
    state.formBonus = {
      title: null,
      description: null,
      type_of_bonus: "percent",
      bonus: 0,
      effective_date: new Date().toISOString().substr(0, 10),
      expire_date: new Date().toISOString().substr(0, 10),
      is_active: true
    };
  }
};

export default {
  namespaced: true,
  state: state,
  actions,
  getters,
  mutations
};
