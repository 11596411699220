import authService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { isLoggedIn: true }, user }
  : { status: { isLoggedIn: false }, user: null };

const actions = {
  login({ commit }, user) {
    return authService.login(user).then(
      user => {
        commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    authService.logout();
    commit("logout");
  }
};

const mutations = {
  loginSuccess(state, user) {
    state.status.isLoggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.status.isLoggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.isLoggedIn = false;
    state.user = null;
  },
  registerSuccess(state) {
    state.status.isLoggedIn = false;
  },
  registerFailure(state) {
    state.status.isLoggedIn = false;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
